// Font family
$font-base: 		'Archivo', sans-serif;
$font-hedding:	'Roboto Slab', sans-serif;

// Colors
$body-color:		#6f7380;
$primary:				#f85a14;
$white:					#ffffff;
$gray-1: 				#f8f9fa; 	// BG Light
$gray-2: 				#cccccc;
$gray-3: 				#6f7380; 	// Body Text
$gray-4: 				#707173;
$gray-5: 				#eeeeee;
$gray-6: 				#293745;
$gray-7: 				#1b1b29;
$gray-8: 				#f85a14;
$gray-9: 				#212c6b;
$black:  				#000000;

// Border color
$border-color: 	        #e8e8e8;
$border-color-2: 	   	  #f8f9fa;
$border-color-3: 	      #f85a14;
$border-color-bottom: 	#212c6b;

// For button and input border radius
$border-radius-0:       0px;
$border-radius-xs:      1px;
$border-radius-sm:      2px;
$border-radius-md:      3px;
$border-radius-lg:      4px;
$border-radius-xl:      5px;
$border-radius-round:   100px;

// Box shadow sizes
$box-shadow:            0px 0px 20px rgba($black, 0.06);
$box-shadow-sm:         0px 4px 8px rgba($black, 0.07);
$box-shadow-lg:         0px 4px 13px rgba($black, 0.05);


