/*****************************
	Pie Chart
*****************************/

	.pie-chart {
		padding: 20px;
  }
  
	.pie-chart.pie-chart-percentage {
		.round-chart {
			display: inline-block;
			position: relative;
			text-align: center;
			margin-bottom: 20px;
			span {
				display: inline-block;
				font-size: 28px;
				color: #ffffff;
				z-index: 1;
				font-weight: 600;
				position: absolute;
			}
			.percent {
				&:after {
					content: "%";
				}
			}
		}
		.chart-title {
			font-size: 16px;
			font-weight: 700;
		}
	}