/*****************************
	Owl Carousel
*****************************/

.owl-carousel {
	.owl-item {
		img {
			width: auto;
			display: inherit;
		}
	}
	.owl-nav {
		cursor: pointer;
		z-index: 999;
		width: 100%;		
		button.owl-prev {
			font-size: 22px;
			width: 46px;
			height: 46px;
			padding: 0px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $primary;
			background-color: rgba($primary, 0.2);
			border: 0px solid $border-color;
			text-align: center;
			position: absolute;
			overflow: hidden;
			left: 0px;
			top: 50%;
			transform: translateY(-50%);
			opacity: 0;
			border-radius: $border-radius-md;
			transition: all 0.5s ease-in-out;
			&:hover {
				background-color: rgba($primary, 0.4);
			}
			i {
				width: 6px;
				padding-left: 1px;
				overflow: hidden;
				transition: all 0.3s ease-in-out;
			}
			&:hover {
				i {
					width: 22px;
				}
			}
			
		}
		button.owl-next {
			font-size: 22px;
			width: 46px;
			height: 46px;
			padding: 0px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $primary;
			background-color: rgba($primary, 0.2);
			border: 0px solid $border-color;
			text-align: center;
			position: absolute;
			overflow: hidden;			
			right: 0px;
			top: 50%;
			transform: translateY(-50%);
			opacity: 0;
			border-radius: $border-radius-md;
			transition: all 0.5s ease-in-out;
			&:hover {
				background-color: rgba($primary, 0.4);
			}
			i {
				width: 6px;
				overflow: hidden;
				transition: all 0.3s ease-in-out;
				&:before {
					margin-left: -14px;
					transition: all 0.3s ease-in-out;
				}	
			}
			&:hover {
				i {
					width: 22px;
					&:before {
						margin-left: 0;
					}
				}
			}		
		}
		
	}
	.owl-controls {
		.owl-dot {
			margin: 0;
			display: inline-block;
		}
	}
	.owl-dots {
		display: inline-block;
		margin-top: 10px;
		text-indent: inherit;
		width: 100%;
		cursor: pointer;
		.owl-dot {
			span {
				background: transparent;
				display: block;
				width: 14px;
				height: 14px;
				margin: 0px 4px;
				text-align: center;
				opacity: 1;
				cursor: pointer;
				position: relative;
				transition: all 0.5s ease-in-out;
				border-radius: $border-radius-round;
				border: 2px solid $gray-9; 
			}
		}
		.owl-dot.active {
			span {
				background-color: $primary;
				border: 2px solid $primary;
			}
		}
	}
	.owl-dot {
		display: inline-block;
		margin: 0px 4px 0;
	}
}

.owl-carousel:hover .owl-nav .owl-prev {
	opacity: 1;
	left: 30px;
}

.owl-carousel:hover .owl-nav .owl-next {
	opacity: 1;
	right: 30px;
}

/* Owl Dots Alignment */
.owl-carousel.owl-nav-bottom-left .owl-dots {
	text-align: left;
}

.owl-carousel.owl-nav-bottom-center .owl-dots {
	text-align: center;
}

.owl-carousel.owl-nav-bottom-right .owl-dots {
	text-align: right;
}

/* Dark Arrow Style */
.owl-carousel {
	.owl-nav {
		button.owl-prev {
			background-color: rgba($white, 0.2);
			color: $white;
			&:hover {
				background-color: rgba($white, 0.4);
			}
		}
	}
}

.owl-carousel {
	.owl-nav {
		button.owl-next {
			background-color: rgba($white, 0.2);
			color: $white;
			&:hover {
				background-color: rgba($white, 0.4);
			}
		}
	}
}
