/*****************************
  Button
*****************************/
button {
	outline: medium none !important;
	color: $primary;
}

.btn {
	overflow: hidden;
	transition: background-color 0.3s;
  }
  .btn::before {
	content: '';
	position: absolute;
	left: -50%;
	width: 200%;
	height: 200%;
	top: -50%;
	transform: translate3d(0, -120%, 0) rotate3d(0, 0, 1, -10deg);
  }
  .btn > span {
	display: block;
  }
  .btn .btn-icon{
	font-size: 20px;
	position: absolute;
	top: 0;
	width: 100%;
	left: 0;
	color: #fff;
	transform: translate3d(0, -100%, 0);
	transition: all 0.3s ease-in-out;
  }
  
  .btn > span,
  .btn .btn-icon{
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	transition: all 0.3s ease-in-out;
  }
  .btn:hover::before {
	animation: consult-btn 0.3s forwards ease-in-out;
  }
  
  .btn:hover {
	  transition: background-color 0s 0.3s;
	}
	
	.btn:hover .btn-icon{
	  top: 50%;
	  transform: translate3d(0, -50%, 0);
	}
	.btn:hover > span {
	  opacity: 0;
	  transform: translate3d(0, 100%, 0);
	}


/* Btn */
.btn {
	display: inline-block;
	position: relative;
	font-size: 14px;
	line-height: 22px;
	padding: 15px 22px;
	font-weight: 700;
	border-radius: $border-radius-md;
	transition: all 0.3s ease-in-out;
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				box-shadow: none;
				outline: none;
			}
		}
		&:not(.disabled) {
			&:active {
				&:focus {
					box-shadow: none;
					outline: none;
					color: $gray-9;
				}
			}
		}
	}
	&:hover {
		box-shadow: none;
		outline: none;
	}
	&:focus {
		box-shadow: none;
		outline: none;
		color: $white;
	}
	&:active {
		box-shadow: none;
		outline: none;
		color: $white;
	}
}
.show {
	>.btn.dropdown-toggle {
		&:focus {
			box-shadow: none;
			outline: none;
			color: $white;
		}
	}
	>.btn-primary.dropdown-toggle {
		background: $primary;
		border-color: transparent;
	}
}

.btn-primary {
	background: $primary;
	border: transparent;
	color: $white;
	&:before {
		background: $gray-9;
		border-color: transparent;
	}
	&:hover {
		background: none;
		border-color: transparent;
	  color: $white;
	  border-radius: $border-radius-md;
		&:not(:disabled) {
			&:not(.disabled).active {
				background: none;
				border-color: transparent;
			}
			&:not(.disabled) {
				&:active {
					background: none;
					border-color: transparent;
				}
			}
		}
	}
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
	background: none;
	border-color: transparent;
	color: $white;
}

.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show>.btn-primary.dropdown-toggle {
	background: $gray-9;
	border-color: transparent;
}

.btn-primary.focus, 
.btn-primary:focus {
	background: $gray-9;
	border-color: transparent;
}

.btn-dark {
	background: $gray-9;
	border: transparent;
	&:before {
		background: $primary;
		border-color: $primary;
	}
	&:hover {
		background: none;
		border-color: $primary;
		border-radius: $border-radius-md;
	}

}

.btn-dark:not(:disabled):not(.disabled):active:focus {
    color: $white;
}

.btn-dark:not(:disabled):not(.disabled).active, 
.btn-dark:not(:disabled):not(.disabled):active, 
.show>.btn-dark.dropdown-toggle {
	background: $gray-9;
	border-color: $gray-9;
}

.btn-dark.focus, 
.btn-dark:focus {
	background: $gray-9;
	border-color: $gray-9;
}

.btn {
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				color: $white;
			}
		}
	}
	+ {
		.btn {
			margin-left: 3px;
		}
	}
}

/* Link */
.btn-link {
	color: $primary;
	padding: 0;
	&:hover {
		color: $gray-9;
		text-decoration: none;
		outline: none;
	}
	&:focus {
		color: $gray-9;
		text-decoration: none;
		outline: none;
	}
}

.btn.btn-link:not(:disabled):not(.disabled):active:focus {
	color: $gray-9;
}

.btn-light {
	background: $white;
	border: transparent;
	color: $primary;
	&:before {
		background: $primary;
		border-color: transparent;
	}
	&:hover {
		background: $primary;
		border-color: transparent;
		color: $white;
		border-radius: $border-radius-md;
	}
	&:active {
		color: $primary;
	}
	&:focus {
		color: $primary;
	}
}

.btn.btn-light:not(:disabled):not(.disabled):active:focus {
	color: $white;
}

.btn-light:not(:disabled):not(.disabled).active, 
.btn-light:not(:disabled):not(.disabled):active, 
.show>.btn-light.dropdown-toggle {
	background: transparent;
	border-color: transparent;
}

.btn-outline-light {
    color: $white;
	border-color: $white;
	padding: 14px 22px;
	&:before {
		background: $white;
		border-color: $white;
	}
	&:hover {
		background: $white;
		border-color: $white;
	}
	.btn-icon {
		color: $primary;
	}
	span {
		color: $white;
	}
	&:hover {
		span {
			color: $primary;
		}
	}
}

a.text-dark {
	color: $gray-9 !important;
}

a.text-dark:focus, 
a.text-dark:hover {
	color: $primary !important;
	transition: all 0.3s ease-in-out;

}

/* Button Size */
.btn.btn-sm {
    padding: 13px 22px;
    font-size: 13px;
    line-height: 18px;
}
.btn.btn-md {
    padding: 14px 26px;
    line-height: 22px;
    font-size: 14px;
}
.btn.btn-lg {
    padding: 16px 30px;
    font-size: 15px;
    line-height: 24px;
}
.btn.btn-xl {
    padding: 18px 32px;
    font-size: 16px;
    line-height: 24px;
}

/* Button Outline */
.btn-outline-dark {
	border-color: $gray-9;
	color: $gray-9;
	padding: 14px 22px;
	&:before {
		background: $gray-9;
		border-color: $gray-9;
	}
	&:hover {
		background: $gray-9;
		color: $white;
		border-color: $gray-9;
	}
	&:focus {
		background: $gray-9;
		color: $white;
		border-color: $gray-9;
	}
}

.btn-outline-dark:not(:disabled):not(.disabled):active {
	background: $gray-9;
	color: $white;
	border-color: $gray-9;
}
.btn-outline-primary {
	color: $primary;
	border-color: $primary;
	padding: 14px 22px;
	&:before {
		background: $primary;
		border-color: $primary;
	}
	&:hover {
		background: $primary;
		color: $white;
		border-color: $primary;
	}
	&:focus {
		background: transparent;
		color: $white;
		border-color: $primary;
	}
	span {
		color: $primary;
	}
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
	background: $primary;
	color: $white;
	border-color: $primary;
}

/* Border style */
.btn-outline-primary.btn-border:hover {
	background: transparent;
	border-color: $gray-9;
	color: $gray-9;
}

.btn-outline-primary.btn-border:focus {
	background: transparent;
	border-color: $gray-9;
	color: $gray-9;
}

.btn-outline-primary.btn-border:not(:disabled):not(.disabled):active {
	background: transparent;
	border-color: $gray-9;
	color: $gray-9;
}

.btn-outline-dark.btn-border:hover {
	background: transparent;
	border-color: $primary;
	color: $primary;
}

.btn-outline-dark.btn-border:focus {
	background: transparent;
	border-color: $primary;
	color: $primary;
}

.btn-outline-dark.btn-border:not(:disabled):not(.disabled):active {
	background: transparent;
	border-color: $primary;
	color: $primary;
}

/* Without Icon */
.btn.btn-wi:hover > span {
	opacity: 1;
	transform: translate3d(0, 0%, 0);
}

@-webkit-keyframes consult-btn {
  50% {
    transform: translate3d(0, -50%, 0) rotate3d(0, 0, 1, -10deg);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
  }
}
@keyframes consult-btn {
  50% {
    transform: translate3d(0, -50%, 0) rotate3d(0, 0, 1, -10deg);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
  }
}


/*****************************
  Responsive
*****************************/


@media (max-width:575px) {

	/* Button Size */

	.btn-outline-primary {
		padding: 10px 16px;
		font-size: 12px;
		line-height: 18px;
	}

	.btn-outline-dark {
		padding: 10px 16px;
		font-size: 12px;
		line-height: 18px;
	}

	.btn-outline-light {
		padding: 10px 16px;
		font-size: 12px;
		line-height: 18px;
	}

}

