/*****************************
  List
*****************************/

  ul.list {
    padding: 0;
    margin: 0;
    li{
	    list-style: none;
	    margin-bottom: 15px;
      display: flex;
	    i{
        margin-right: 10px;
        width: 22px;
        height: 22px;
        font-size: 12px;
        line-height: 22px;
        text-align: center;
        color: $primary;
        background: rgba($primary, 0.1);
        border-radius: $border-radius-round;
	    }
     	&:last-child {
 	    margin-bottom: 0;
      }
    }
  }

ul.list-default {
    padding: 0;
    margin: 0;

    li {
         margin-left: 18px;
         margin-bottom: 5px;
        &:last-child {
        margin-bottom: 0;
         }
       }
}

  ol.list {
      padding: 0;
      margin: 0;

        li {
         margin-left: 18px;
         margin-bottom: 5px;
        &:last-child {
        margin-bottom: 0;
         }
       }

  }