/*****************************
	Progress Bar
*****************************/
	.progress {
		background-color: $gray-5;
		border-radius: $border-radius-sm;
		.progress-bar {
			background-color: $primary;
			border-radius: $border-radius-sm;
		}
	}

  /* Progress Bar Style 1 */
	.progress-style-1 {
		.progress-item {
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0px;
			}
			.progress-title {
				color: $gray-9;
				font-size: 14px;
				font-weight: 700;
			}
			.progress {
				overflow: inherit;
				.progress-bar {
					position: relative;
					span {
						background: $white;
						color: $gray-9;
						font-weight: 700;
						font-size: 14px;
						line-height: 1;
						padding: 3px 6px;
						position: absolute;
						right: 0;
						top: -20px;
					}
				}
			}
		}
	}


