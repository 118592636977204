/*****************************
  Header
*****************************/

/* Header Style 01 */
.header {
  background: $white;
  z-index: 2;
  position: relative;
  box-shadow: 0px 3px 12px rgba(0,0,0,0.02);
}

/* Topbar */
.header .topbar-inner {
  padding: 14px 0;
  border-bottom: 1px solid rgba($gray-9, 0.05);
  ul {
    display: flex;
    margin-bottom: 0;
    color: $white;
    li {
      padding: 0 10px;
    }
  }
  a {
    color: $gray-9;
    font-size: 12px;
    &:hover {
      color: $primary;
    }
  }
}

.header .topbar .social {
  ul {
    margin: 0;
    li {
      display: inline-block;
      padding: 0 6px;
      &:last-child {
        padding-right: 0;
      }
      a {
        color: $gray-9;
        font-size: 12px;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}

/* Navbar */
.header .navbar {
  .navbar-nav{
    .nav-link {
      font-weight: 600;
      font-size: 14px;
      padding: 12px 8px 12px 0;
      color: $gray-9;
      /*text-transform: capitalize;*/
      i {
        font-weight: bold;
      }
      &:hover {
        color: $primary;
      }
    }
  }
}

.header {
	.navbar {
		.navbar-nav {
      align-items: center;
			.nav-item {
				margin-right: 20px;
				&:last-child {
					margin-right: 0px;
				}
				.nav-link {
					position: relative;
					transition: all 0.3s ease-in-out;
					left: 0;
					&:hover {
						color: $primary;
          }
				}
			}
			.nav-item.active {
				.nav-link {
					color: $primary;
				}
			}
		}
	}
}

.header {
  .navbar-brand {
    padding: 17px 0px;
    margin-right: 0;
    color: $white;
    flex: 0 0 180px;
    transition: none;
    /*  img {
      height: 50px;
    }*/
  }
}

/* Header Sticky */
.header.is-sticky {
	position: fixed;
	top: 0;
	z-index: 99;
	width: 100%;
	background-color: $white;
	box-shadow: $box-shadow;
	padding: 0px;
	border-bottom: solid 1px #dcdcdc;
	.topbar {
		display: none;
	}
	.navbar {
		.navbar-brand {
			padding: 12px 0px;
			img {
				/*height: 30px;*/
			}
		}
	}
}

.header{
  .navbar-nav{
    li{
      >a{
          align-items: center;
          display: flex;
          padding: 3px 0px;
          color: $gray-9;
          /*text-transform: capitalize;*/
          font-size: 14px;
          &:hover{
            color: $primary;
          }
          i {
            margin-left: 5px;
            font-size: 10px;
          }
        }
    }
  }
}

/* Mega Menu */
.megamenu {
  padding: 20px 20px;
  width: 100%;
  >div{
    >li{
      > ul{
          padding: 0;
          margin: 0;
        >li{
            list-style: none;
            >a{
              display: block;
              padding: 3px 20px;
              clear: both;
              font-weight: normal;
              line-height: 1.428571429;
              color: $gray-9;
              white-space: normal;
              &:hover{
                text-decoration: none;
                color: $gray-9;
                background-color: $gray-1;
              }
              &:focus{
                text-decoration: none;
                color: $gray-9;
                background-color: $gray-1;
              }
           }
        }
      }
    }
  }
}

.megamenu.disabled{
  >a{
    &:hover{
      color: $gray-1;
      text-decoration: none;
      background-color: transparent;
      background-image: none;
      cursor: not-allowed;
    }
    &:focus{
      color: $gray-1;
      text-decoration: none;
      background-color: transparent;
      background-image: none;
      cursor: not-allowed;
    }
  }
}

.header {
	.megamenu {
		.dropdown-item {
			padding: 0px;
		}
	}
	li {
		>.dropdown-item {
			&:focus {
				color: $primary;
				background: none;
			}
			&:hover {
				color: $primary;
				background: none;
			}
		}
	}
	.dropdown-item.active {
		background: none;
	}
	.dropdown-item {
		&:active {
			background: none;
		}
		&:focus {
			background: none;
		}
		&:hover {
			background: none;
		}
	}
}

.header .dropdown-toggle::after {
  content: none;
}

.header .navbar-collapse {
  align-items: inherit;
  position: relative;
}

.header {
	.navbar {
		.dropdown-menu {
			a.dropdown-item {
				min-width: 180px;
			}
			padding: 0px;
			z-index: 9999;
			li {
				a {
					i {
						margin-left: auto;
						margin-right: 20px;
					}
				}
			}
		}
	}
}

.navbar {
	.dropdown {
		>.dropdown-menu {
			li {
				>a {
					font-size: 13px;
					font-weight: 500;
					padding: 0px;
					color: $gray-9;
					border-radius: 3px;
					position: relative;
					&:hover {
						color: $primary;						
					}					
					span {
						padding: 10px 20px;
					}
				}
				&:last-child {
					border-bottom: none;
					> a span {
						&:before {
							content: none;
						}
					}			
				}
			}
			.nav-title {
				color: $primary;
				padding: 12px 20px;
			}
		}
    }

	.navbar-nav {
		.dropdown-menu {
			li.active {
				>a {
					span {
						color: $primary;
						&:before {
							transform: scaleX(1);
							transform-origin: left center;
						}
					}
				}
			}
		}
	}
}

/* Header Style 02 */
.header.header-style-02 {
  box-shadow: none;
	.navbar-brand{
		width: 180px;
		padding: 10px 0px;
	}
	.navbar {
		.navbar-nav {
			.nav-link {
				color: $white;
			}
		}
		.dropdown-menu {
			left: 0;
			transform: translate3d(0%, 0px, 0);
		}
		.dropdown {
			&:hover {
				>.dropdown-menu {
					transform: translate3d(0%, 0px, 0);
				}
			}
		}
		.dropdown-menu.megamenu {
			left: 0;
		}
	}
	.header-bottom {
		.navbar-brand {
			display: none;
		}
	}
	.header-bottom.is-sticky {
		.navbar-brand {
			display: block;
		}
	}
	.search .search-btn {
		color: $white;
	}
}



/* Search */
.search {
	.search-btn {
		display: block;
		height: 22px;
		line-height: 22px;
		position: relative;
		text-indent: -9999px;
		width: 14px;
		color: $gray-9;
		transition: color 300ms ease 0s;
		transition: 0.5s ease-in-out;
		&:before {
			content: "\f002";
			font-family: "Font Awesome 5 Free";
			font-size: 14px;
			font-weight: 900;
			left: 0;
			position: absolute;
			text-indent: 0;
			top: 0;
		}
	}
	.search-box {
		button {
			background: transparent;
			border: none;
			cursor: pointer;
			font-size: 14px;
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			transition: 0.5s ease-in-out;
		}
	}
		.form-control {
			box-shadow: 0px 5px 10px rgba($black, 0.1);
			border: none;
		}
}
.search.search-open {
	.search-btn {
		&:before {
			content: "\f00d";
		}
	}
	.search-box {
		opacity: 1 !important;
		visibility: visible !important;
		transform: rotateX(0deg);
		.form-control {
			padding-right: 50px;
		}
	}
}
.search-box {
	display: block;
	margin: 0;
	opacity: 0 !important;
	position: absolute;
	right: 0;
	top: 35px;
	transition: visibility 300ms ease 0s, opacity 300ms ease 0s;
	visibility: hidden;
	width: 327px;
	z-index: 888;
	border-radius: 3px;
	transform: rotateX(90deg);
	transform-origin: 0 0;
	transition: all 400ms ease;
}
.search-box.open {
	visibility: visible;
	opacity: 1;
	-webkit-transform: translateY(10px);
	transform: translateY(10px);
}

/*****************************
  Responsive
*****************************/

@media (min-width:1200px) {
  .navbar-nav .mega-menu {
    position: static;
  }

  .header {
    .navbar {
      .dropdown-menu {
        margin: 0px;
		font-size: 14px;
		background-color: $white;
        border-radius: $border-radius-md;
        border: none;
        left: 100%;
        transform: translate3d(-50%, 10px, 0);
        transition: all 0.3s ease-in-out;
        transition-property: opacity, visibility, transform;
        transform-origin: top center;
        box-shadow: 0px 5px 10px rgba($black, 0.1);
        visibility: hidden;
        opacity: 0;
        display: block !important;
        .dropdown-submenu {
          .dropdown-menu {
            left: 100%;
            right: auto;
            transform: translate3d(0px, 10px, 0);
          }
          .dropdown-menu.left-side {
            right: 100%;
            left: auto;
            transform: translate3d(-15px, 10px, 0);
          }
        }
      }
      .dropdown-menu.megamenu {
        left: 50%;
      }
      .dropdown-menu.dropdown-menu-lg {
        min-width: 540px;
      }
      .dropdown {
        &:hover {
          >.dropdown-menu {
            transform: translate3d(-50%, 0, 0);
            visibility: visible;
            opacity: 1;
          }
        }
      }
      li {
        &:hover {
          >ul.dropdown-menu {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }

  .dropdown-submenu {
    position: relative;
    >.dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -6px;
    }
  }

}

@media (max-width:1199px) {

  /* Header */
	.header {
		.navbar-collapse {
			position: absolute;
			top: 100%;
			z-index: 999;
			background: $white;
			width: 100%;
			left: 0;
			margin-left: 0px;
			border-top: 1px solid #eeeeee;
			border-bottom: 1px solid #eeeeee;
			box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
		}
		.navbar {
			.navbar-nav {
        align-items: initial;
				.nav-link {
					color: $gray-9;
					padding: 10px 20px;
					i {
						position: absolute;
						right: 20px;
          }
				}
			}
			.dropdown-menu {
				margin: 0px;
				font-size: 14px;
				border-radius: 0px;
				border: none;
				-webkit-box-shadow: none;
				box-shadow: none;
				background: #f6f6f6;
				padding: 14px 30px;
				font-size: 13px;
			}
			.nav-title {
				margin-top: 20px;
			}
			.navbar-toggler {
				position: absolute;
				right: 0px;
			}
		}
	}

  /* Header Style 01 */
	.header.default {
		.navbar {
			position: relative;
			background: $white;
			.navbar-nav {
				.nav-item {
					margin-right: 0;
				}
				.nav-link {
					color: $gray-9;
					padding: 12px;
				}
			}
		}
	}

  /* Header Style 02 */
  .header.header-style-02 {
    .navbar-brand {
      display: none;
    }
    .header-bottom {
      .navbar-brand {
        display: inherit;
      }
    }
    .navbar {
      .navbar-nav {
        .nav-link {
          color: $gray-9;
          padding: 12px;
        }
        .nav-item {
          margin-right: 0;
        }
      }
    }
  }
  
  .header.default .navbar .navbar-nav .nav-item.header-search {
    display: none;
  }

}

@media (max-width:575px) {

  /* Header */
  .header {
    .navbar {
      .navbar-brand {
        flex: 0 0 130px;
        padding: 5px 0px;
      }
      .dropdown-menu {
        max-height: 300px;
        padding : 0 0 0 15px;
        overflow-x: scroll;
      }
    }
  }

}
