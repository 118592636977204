/*****************************
    Team
*****************************/
  /* Team Style 2 */
  .team-grid {
    .team.team-style-1 {
        margin: 0;
    }
  }
  
  .team.team-style-1 {
      border-radius: $border-radius-md;
      box-shadow: $box-shadow;
      margin: 30px 0px;
      .team-img {
          img {
              border-radius: $border-radius-md;
          }

      }

      .team-info {
          padding: 20px;
          position: relative;
          transition: all 0.3s ease-in-out;
          background: $white;
          margin-top: -20px;

          .btn-link {
              font-size: 12px;
              font-weight: 700;
              position: absolute;
              bottom: 20px;
              left: 20px;
              opacity: 0;
              visibility: hidden;
              transition: all 0.3s ease-in-out;
          }
          .team-title {
            a  {
              &:hover {
                color: $primary;
              }
            }
          }

      }

      &:hover {
          .team-info {
              margin-top: -60px;
              padding-bottom: 60px;

              .btn-link {
                  opacity: 1;
                  visibility: visible;
              }

          }

      }

  }