/*****************************
  Footer
*****************************/

.footer {
	color: rgba($white, 0.5);
	ul {
		margin-bottom: 0;
	}
	.footer-logo{
		width: 60%;
	}
	.footer-email{
		a{
			color: rgba($white, 0.5);
		}
	}
}

/* Contact Address */
.footer-contact-address {
	ul {
		li {
			display: flex;
			margin-bottom: 15px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	i {
		color: $primary;
		font-size: 30px;
		margin-right: 16px;
	}
	.address-info {
		color: $white;
		h6 {
			color: $primary;
			font-size: 13px;
			margin-bottom: 0;
		}
		p {
			font-size: 13px;
			margin-bottom: 0;
		}
	}
}

/* Social icon */
.social-icon {
    li {
        display: inline-block;
        margin-right: 50px;
        &:last-child {
            margin-right: 0;
        }
        a {
            font-size: 18px;
            color: $white;
        }
        &:hover {
            a {
                color: $primary;
            }
        }
    }
}

.address {
	span {
		font-size: 16px;
		font-weight: 500;
	}
}

.flag-svg{
    height: 30px;
}

a.footer-logo-text {
	&:hover {
		color: $primary !important;
	}
	&:focus {
		color: $primary !important;
	}
}

.footer-link {
    display: flex;
    ul {
        padding-right: 50px;
        &:last-child {
            padding-right: 0px;
        }
        li {
            a {
                color: rgba($white, 0.5);
                padding: 7px 0px;
                display: block;
            }
            &:hover {
                a {
                    color: $primary;
                }
            }
            &:first-child {
                a {
                    padding-top: 0;
                }
            }
            &:last-child {
                a {
                    padding-bottom: 0;
                }
            }
        }
    }
}

/* subscribe */
.footer-subscribe{
		form{
			.form-group{
				.form-control{
					background:rgba($white,0.2);
					border:1px solid rgba($white,0.02);
					color:$white; 
				}
				.form-control::placeholder {
				  color: $white;
				}
			}
		}
	}

/* Copyright */
.footer-bottom {
	color: $white;
	padding: 30px 0;
	a {
		color: $white;
		&:hover {
			color: $primary;
		}
	}
}

.footer-02 {
	.footer-link {
		display: inherit;
	}
}

/*****************************
  Responsive
*****************************/

@media (max-width: 991px) {
	.footer{
		.social-icon{
			li{
        margin-right: 30px;
			}
		} 
	}
}

@media (max-width: 767px) {
	.footer{
		.social-icon{
			li{
        margin-right: 20px;
			}
		} 
	}
}

@media (max-width:575px) {
	.footer-link{
		display: inline-block;
		ul{
			margin-right: 0;
		}
	}
}

