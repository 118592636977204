/*****************************
	Service
*****************************/

	.service-item {
		box-shadow: $box-shadow;
		border-radius: $border-radius-md;
		.service-img {
			position: relative;

			&:before {
				content: "";
				background: rgba($gray-9, 0.8);
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				border-radius: 3px 3px 0 0;
				transition: all 0.3s ease-in-out;
			}
			
			img {
				border-radius: 3px 3px 0 0;
				width: 100%;
			}

			a {
				background-color: $primary;
				height: 50px;
				width: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 16px;
				color: $white;
				position: absolute;
				left: 50%;
				top: 20%;
				opacity: 0;
				border-radius: $border-radius-md;
				transform: translateX(-50%) translateY(-50%);

				&:hover {
					background-color: $white;
					color: $primary;
				}

			}

		}

		&:hover {
			.service-img {
				&:before {
					opacity: 1;
				}

				a {
					top: 50%;
					opacity: 1;
				}

			}

		}

		.service-info {
			padding: 72px 30px 30px 30px;
			margin-top: -44px;
			

			.service-info-title {
				margin-bottom: 0;

				a {
					&:hover {
						color: $primary;
					}

				}

			}

		}

	}

	.owl-carousel {
		.service-item {
			margin: 30px 0px;
		}
	}

@media (max-width:767px) {

	.service-item .service-info {
		padding: 62px 20px 20px 20px;
  }
  
}